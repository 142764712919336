(function(NS, $) {
	function ContextScaffold(parentInstance) {
		this.parent = parentInstance;

		var isAwake = false;
		this.isAwake = function() {
			return isAwake;
		};

		this.wake = function() {
			if (this.isAwake()) return;
			isAwake = true;
			this.emitEvent('wake');
		};

		this.sleep = function() {
			if (!this.isAwake()) return;
			isAwake = false;
			this.emitEvent('sleep');
		};
	}

	ContextScaffold.prototype = Object.create(EvEmitter.prototype);

	NS.ContextScaffold = ContextScaffold;
})(window[NS], jQuery);
