(function(NS, $) {
	// https://davidwalsh.name/add-rules-stylesheets
	function newSheet(title) {
		var style = document.createElement('style');
		style.type = 'text/css';
		style.title = title;
		document.head.appendChild(style);
		return style.sheet ? style.sheet : style.styleSheet;
	}

	function injectStyles(newRules, styleTitle) {
		var documentStyleSheets = document.styleSheets;
		var styleSheet;

		// Check if styleSheet by same title already exists, if so use it
		for (var a = 0; a < documentStyleSheets.length; a++) {
			if (documentStyleSheets[a].title === styleTitle) {
				styleSheet = documentStyleSheets[a];
				break;
			}
		}

		if (typeof styleSheet === "undefined") styleSheet = newSheet(styleTitle);

		var existingStyleRules = styleSheet.cssRules;

		// Loop through existing rules to overrite them
		for (var b = 0; b < existingStyleRules.length; b++) {
			var existingRule = existingStyleRules[b];

			for (var c = 0; c < newRules.length; c++) {
				if (newRules[c].indexOf(existingRule.selectorText) > -1) { // Rule match
					styleSheet.deleteRule(b);
					styleSheet.insertRule(newRules[c], existingStyleRules.length);
					newRules.splice(c, 1);
				}
			}
		}

		for (var d = 0; d < newRules.length; d++) {
			styleSheet.insertRule(newRules[d], existingStyleRules.length);
		}
	}

	NS.injectStyles = injectStyles;
})(window[NS], jQuery);
