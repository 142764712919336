(function(NS, $) {
	var appProto = NS.App.prototype;

	function TabSetNav(element, tabSetInstance) {
		this.$el = $(element);
		this.tabSetInstance = tabSetInstance;
		this._init();
	}

	TabSetNav.prototype = Object.create(EvEmitter.prototype);
	var tabSetNavProto = TabSetNav.prototype;

	tabSetNavProto._init = function() {
		var me = this;

		// Extend with some context spying and switching
		NS.ContextController.call(me, {
			// small: NS.TabSetNavContextSmall,
			// large: NS.TabSetNavContextLarge
		});

		// Lights out…And away we go!
		me._build();
	}

	tabSetNavProto._build = function() {
		var me = this;

		me.$window = $(window);
		me.isDestroying = false;

		me.$tabSet = me.tabSetInstance.$el;
		me.$tabSetHeader = me.tabSetInstance.$tabSetHeader;
		me.$tabSetNav = me.tabSetInstance.$tabSetNav;

		me.$tabSetNavItems = me.$tabSetNav.find('> ul > .tab-set__nav-item');

		me._activeTabIndex = null;

		me.$tabSetNav.pinny({
			behaviour: 'pinAndGhost',
			offsets: {
				pin: {
					measure: function() {
						return this.cache.tabSetHeaderOffsetY - this.cache.siteHeaderHeight;
					}
				},
				ghost: {
					measure: function() {
						return this.cache.tabSetHeaderOffsetY - this.cache.siteHeaderHeight + this.cache.tabSetHeight - this.cache.tabSetItemBottomPadding - this.cache.height;
					}
				}
			},
			cache: {
				height: function() {
					return this.$el.height();
				},
				siteHeaderHeight: function() {
					return NS.appInstance.siteHeader.getHeightIfPinnable();
				},
				tabSetHeight: function() {
					return me.$tabSet.height();
				},
				tabSetItemBottomPadding: function() {
					var targets = me.tabSetInstance.tabSetTargets;
					var lastTarget = targets[targets.length - 1];
					var instance = me.tabSetInstance.tabSetItemInstances[lastTarget];

					if (!instance || !instance.$media) {
						return 0;
					}

					return parseFloat(instance.$media.css('paddingBottom'), 10);
				},
				tabSetHeaderOffsetY: function() {
					return me.$tabSetHeader.offset().top;
				}
			}
		});

		me.tabSetNavPinnyInstance = me.$tabSetNav.data('pinny');

		me.$tabSetNav.on('statechange.pinny', function(ev, from, to, fromRefreshing, refreshing, instance) {
			var $this = $(this);

			// DEFAULT
			if (from === "default") {
				me.$tabSetHeader.css({
					height: $(this).height()
				});
			}

			if (to === "default") {
				// Reset
				me.$tabSetHeader.css({
					height: ''
				});
			}

			if (to === "pinned") {
				$this.css({
					top: NS.appInstance.siteHeader.getHeightIfPinnable()
				});
			}

			if (from === "pinned") {
				$this.css({
					top: ''
				});
			}

			if (to === "ghosting") {
				$this.css({
					bottom: instance.cache.tabSetItemBottomPadding
				});
			}

			if (from === "ghosting") {
				$this.css({
					bottom: ''
				});
			}
		});

		me.tabSetInstance
			.on('tabchange', function() {
				me.updateNav.apply(me, arguments);
			});
	}

	tabSetNavProto.updateNav = function(newTabIndex, prevTabIndex) {
		var me = this;

		if (prevTabIndex !== null) {
			$(me.$tabSetNavItems[prevTabIndex]).removeClass('is-active');
		}

		if (newTabIndex !== null) {
			var $newTab = $(me.$tabSetNavItems[newTabIndex])
			$newTab.addClass('is-active');

			var navWidth = me.$tabSetNav.outerWidth();
			var navMaxScrollLeft = me.$tabSetNav[0].scrollWidth - navWidth;
			var tabScrollOffset = $newTab.position().left;
			var tabWidth = $newTab.outerWidth();

			var tabCenterOffset = Math.max(((navWidth - tabWidth) / 2), 0);
			var scrollOffset = Math.min(Math.max(tabScrollOffset - tabCenterOffset, 0), navMaxScrollLeft);

			/* global Expo */
			TweenLite.to(me.$tabSetNav[0], 0.25, {
				scrollTo: {
					x:  scrollOffset
				},
				ease: Expo.easeOut
			});
		}
	}

	tabSetNavProto.destroy = function() {
		var me = this;

		me.isDestroying = true;
	}

	NS.TabSetNav = TabSetNav;
})(window[NS], jQuery);
