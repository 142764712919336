(function(NS, $) {
	function HeaderContextLarge(parentInstance) {
		// Add some scaffolding (wake, sleep etc…)
		NS.ContextScaffold.apply(this, arguments);

		var me = this;
		me.$el = me.parent.$el;

		me.$window = $(window);

		me.on('wake', function() {
			me.parent.isPinned = false;
			me.parent.isPinnable = true;
			me.$window.on('scroll.' + NS.NAMESPACE + '.HeaderContextLarge', $.proxy(me.scroll, me));
			me.refresh();
		});

		me.on('sleep', function() {
			me.parent.isPinned = false;
			me.parent.isPinnable = false;
			me._resetStyles('all');
			me.$window.off('scroll.' + NS.NAMESPACE + '.HeaderContextLarge');
		});
	}

	HeaderContextLarge.prototype = Object.create(NS.ContextScaffold.prototype);
	HeaderContextLarge.prototype.constructor = HeaderContextLarge;
	var headerContextLargeProto = HeaderContextLarge.prototype;

	headerContextLargeProto._resetStyles = function(target) {
		var me = this;
		if (target === 'all' || target === 'unpin') {
			me._resetProps(me.parent.$el, {height: ''}, 'is-pinned');
		}

		if (target === 'all' || target === 'unshift') {
			me._resetProps(me.parent.$inner, {transform: '', top: ''});
		}
	};

	headerContextLargeProto._resetProps = function($el, css, classes) {
		$el
			.css(css)
			.removeClass(classes);
	};

	headerContextLargeProto.refresh = function() {
		var me = this;

		me.headerInnerHeight = me.parent.getHeight();
		me.headerPadding = me.parent.getPadding();

		me.scroll();
	};

	headerContextLargeProto.scroll = function() {
		var me = this;

		me.scrollTop = (window.pageYOffset === undefined) ? (document.documentElement || document.body.parentNode || document.body).scrollTop : window.pageYOffset;

		// Pinning
		if (me.scrollTop < me.headerPadding) {
			// Shifting
			var offset = (me.scrollTop / 2) + 'px';
			var offsetCSS = {};

			if (Modernizr.csstransforms) {
				offsetCSS.transform = 'translateY(' + offset + ')';
			} else {
				offsetCSS.top = offset;
			}
			me.parent.$inner.css(offsetCSS);

			// Already unpinned no need to repeat unpinning
			if (!me.parent.isPinned) return;
			me.parent.isPinned = false;
			me._resetStyles('unpin');
		} else {
			// Already pinned no need to reinit pinning
			if (me.parent.isPinned) return;
			me.parent.isPinned = true;

			me._resetStyles('unshift');

			me.parent.$el
				.css('height', me.headerInnerHeight + me.headerPadding)
				.addClass('is-pinned');
		}
	};

	NS.HeaderContextLarge = HeaderContextLarge;
})(window[NS], jQuery);
