(function(NS, $) {
	var appProto = NS.App.prototype;

	function TabSet($element) {
		this.$el = $element;
		this._init();
	}

	TabSet.prototype = Object.create(EvEmitter.prototype);
	var tabSetProto = TabSet.prototype;

	tabSetProto._init = function() {
		var me = this;

		// Extend with some context spying and switching
		// No contexts supplied so only making a context spy
		NS.ContextController.call(me, {});

		me.$window = $(window);
		me.$tabSetHeader = me.$el.find('> .content-item--tab-set__header');
		me.$tabSetNav = me.$tabSetHeader.find('> .tab-set__nav');

		me._bindings();
		me._build();
	}

	tabSetProto._build = function() {
		var me = this;

		me.$body = $(document.body);
		me.$site = $('.site-section--main');

		me.isDestroying = false;

		me.$scrollElement = me.$window;

		me.tabSetOffsets = [];
		me.tabSetTargets = [];
		me.tabSetNavItems = [];
		me.tabSetItems = [];
		me.scrollHeight = 0;
		me.activeTabTarget = null;
		me.activeTabIndex = null;

		// Key: target/href #id
		me.tabSetItemInstances = {};

		// Find valid tab nav items and corresponding .tab-set-item elements
		me.$tabSetNav.find('a')
			.map(function() {
				var $el = $(this);
				var href = $el.attr('href');
				var $targetTabSetItem = /^#./.test(href) && $(href);

				return ($targetTabSetItem && $targetTabSetItem.length && [[href, $el, $targetTabSetItem]]) || null;
			})
			.sort(function(a, b) {
				return a[0] - b[0];
			})
			.each(function() {
				me.tabSetTargets.push(this[0]);
				me.tabSetNavItems.push(this[1]);
				me.tabSetItems.push(this[2]);
			});

		// Build individual item components and register on app instance
		for (var i = 0; i < me.tabSetItems.length; i++) {
			me.tabSetItemInstances[me.tabSetTargets[i]] = NS.appInstance.registerComponentInstance(NS['TabSetItem'], 'TabSetItem', me.tabSetItems[i], me);
		}

		// Build nav component and register on app instance
		me.tabSetNavInstance = NS.appInstance.registerComponentInstance(NS['TabSetNav'], 'TabSetNav', me.$tabSetNav, me);
	}

	tabSetProto._bindings = function() {
		var me = this;

		me.contextSpy.on('contextchange', function(newContext, oldContext) {
			// Trigger contextChange event on instance (nav, items will be listening)
			me.emitEvent('contextchange', arguments);
		});

		var resizeDelegate = function() {
			return me.isDestroying ? false : me.refresh();
		};

		var scrollDelegate = function() {
			return me.isDestroying ? false : me.scroll();
		};

		me._resizeDelegate = resizeDelegate;

		me.$window
			.on('resize.' + NS.NAMESPACE + '.TabSet', resizeDelegate)
			.on('scroll.' + NS.NAMESPACE + '.TabSet', scrollDelegate);

		// HACK check async to allow time to bind nested listeners
		setTimeout(function() {
			me.refresh();
		});
	}

	tabSetProto.getScrollHeight = function() {
		var me = this;
		return me.$scrollElement[0].scrollHeight || Math.max(me.$body[0].scrollHeight, document.documentElement.scrollHeight);
	}

	tabSetProto.activateTab = function(index, target) {
		var me = this;
		me.emitEvent('tabchange', [index, me.activeTabIndex]);

		me.activeTabTarget = target;
		me.activeTabIndex = index;
	}

	tabSetProto.refresh = function() {
		var me = this;

		me.scrollHeight = me.getScrollHeight();

		var headerHeight = NS.appInstance.siteHeader.getHeightIfPinnable();

		me.tabSetOffsets = me.tabSetItems.map(function($el, index, array) {
			return parseInt($el.offset().top, 10) - headerHeight
		});

		me.scroll();

		me.emitEvent('refresh', arguments);
	}

	tabSetProto.scroll = function() {
		var me = this;

		var offsets = me.tabSetOffsets;

		if (!offsets.length) return;

		var scrollTop = (window.pageYOffset === undefined) ? (document.documentElement || document.body.parentNode || document.body).scrollTop : window.pageYOffset;
		var scrollHeight = me.getScrollHeight();
		var maxScroll = scrollHeight - me.$scrollElement.height();
		var targets = me.tabSetTargets;
		var activeTarget = me.activeTabTarget;
		var i;

		if (me.scrollHeight !== scrollHeight) {
			me.refresh();
		}

		if (scrollTop >= maxScroll) {
			return activeTarget !== (i = targets[targets.length - 1]) && me.activateTab(targets.length - 1, i);
		}

		if (activeTarget === null || activeTarget !== targets[0] && scrollTop < offsets[0]) {
			return me.activateTab(0, targets[0]);
		}

		for (i = offsets.length; i--;) {
			/* eslint-disable */
			activeTarget != targets[i]
				&& scrollTop >= offsets[i]
				&& (offsets[i + 1] == undefined || scrollTop < offsets[i + 1])
				&& me.activateTab(i, targets[i]);
			/* eslint-enable */
		}
	}

	tabSetProto.destroy = function() {
		var me = this;

		me.isDestroying = true;

		me.$window
			.off('resize.' + NS.NAMESPACE + '.TabSet', me._resizeDelegate)
			.off('scroll.' + NS.NAMESPACE + '.TabSet', me._scrollDelegate);
	}

	NS.TabSet = TabSet;
})(window[NS], jQuery);
