(function(NS, $) {
	var createInstance = (function() {
		var instance;
		return {
			initialize: function() {
				//initialize only if not initialized before
				if (typeof instance === "undefined") {
					instance = new App(arguments[0]);
				}
				return instance;
			}
		};
	})();

	function App($element) {
		// Bail out son
		if ($element === undefined || !($element instanceof jQuery) || !$element.length) return;

		this.$el = $element;

		// Don’t expose GUID itself
		var GUID = 1;
		this.newGUID = function() {
			return GUID++;
		}

		this.componentInstances = {};

		// Let’s get the show on the road!
		this._create();
	}

	var proto = App.prototype;

	proto.createMethods = [];

	proto._create = function() {
		for (var i=0, len = this.createMethods.length; i < len; i++) {
			var method = this.createMethods[i];
			this[method]();
		}
	};

	proto.registerComponentInstance = function(componentConstructor, componentConstructorName, $element) {
		var me = this;

		if (!me.componentInstances[componentConstructorName]) me.componentInstances[componentConstructorName] = {};

		var guid = me.newGUID();

		// If we’re building this component manually we’ll add the component name as a data attr for convenience
		// Allows for window[NS].appInstance.componentInstances[$($0).attr('data-js-component')][$($0).attr('data-js-component-guid')] to retrieve instance again
		var attrs = {
			'data-js-component-guid': guid
		}

		if (typeof $element.attr('data-js-component') === 'undefined') attrs['data-js-component'] = componentConstructorName;

		$element.attr(attrs);

		// http://stackoverflow.com/a/6062379
		var instance = Object.create(componentConstructor.prototype);
		// Argument slicing is bad, but meh!
		// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Functions/arguments
		var args = [].slice.call(arguments, 2);
		componentConstructor.apply(instance, args);

		return me.componentInstances[componentConstructorName][guid] = instance;
	};

	// Go go gadget!
	$(function() {
		var $site = $('.js--site-body');

		// Build the beast
		NS.appInstance = createInstance.initialize($site);

		// Build components
		$('[data-js-component]').each(function() {
			var $this = $(this);

			var componentConstructorName = $this.attr('data-js-component');

			if (componentConstructorName && NS[componentConstructorName] instanceof Function) {
				NS.appInstance.registerComponentInstance(NS[componentConstructorName], componentConstructorName, $this);
			}
		});

		$(document).on('click.' + NS.NAMESPACE + '.preventdoubleclick', '[preventdoubleclick]', function(ev) {
			var $this = $(this);

			if ($this.data('hasclicked')) {
				ev.preventDefault();
				ev.stopPropagation();
				return;
			}

			$this.data('hasclicked', true);
		});

		var $window = $(window);

		// Now that the beast has been run and components built, trigger a resize to relayout because of new font metrics
		NS.webfontsLoaded.done(function() {
			$window.trigger('resize');
		});

		// Refresh when page loaded
		$window.on('load', function() {
			$window.trigger('resize');
		});
	});

	NS.App = App;
})(window[NS], jQuery);
