(function(NS, $) {
	var appProto = NS.App.prototype;

	function SiteHeader($element) {
		this.$el = $element;
		this._init();
	}

	SiteHeader.prototype = Object.create(EvEmitter.prototype);
	var siteHeaderProto = SiteHeader.prototype;

	siteHeaderProto.defaults = {

	}

	siteHeaderProto._init = function() {
		var me = this;

		// Extend with some context spying and switching
		NS.ContextController.call(me, {
			small: NS.HeaderContextSmall,
			large: NS.HeaderContextLarge
		});

		me.isPinned = false;
		me.isPinnable = false;
		me.$inner = me.$el.find('> .site-header__inner');

		me._bindings();
	}

	siteHeaderProto._bindings = function() {
		var me = this;

		me.contextSpy.on('contextchange', function(newContext, oldContext) {
			return me._updateContext.apply(me, arguments);
		});
	}

	siteHeaderProto.getHeight = function() {
		var me = this;
		return me.$inner.height();
	}

	siteHeaderProto.getHeightIfPinnable = function() {
		var me = this;
		return me.isPinnable && me.getHeight() || 0;
	}

	siteHeaderProto.getPadding = function() {
		var me = this;
		return parseFloat(me.$el.css('paddingTop')) + parseFloat(me.$el.css('paddingBottom'));
	}

	appProto.createMethods.push('_createSiteHeader');
	appProto._createSiteHeader = function() {
		this.siteHeader = new SiteHeader($('.js--site-header'));
	};
})(window[NS], jQuery);
