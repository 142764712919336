(function(NS, $) {
	function HomeCarouselContextLarge(parentInstance) {
		// Add some scaffolding (wake, sleep etc…)
		NS.ContextScaffold.apply(this, arguments);

		var me = this;
		me.$el = me.parent.$el;

		me._build();

		me.on('wake', function() {
			me.$overflowCarousel.appendTo(me.parent.$el);

			me.parentIndex = me.parentFlickityInstance.selectedIndex;

			me.$overflowFlickityEl.flickity({
				setGallerySize: false,
				cellSelector: '.media-carousel__item',
				draggable: false,
				wrapAround: true,
				pageDots: false,
				prevNextButtons: false,
				accessibility: false,
				mimic: {
					target: me.parent.$flickityEl[0],
					indexOffset: 1
				}
			});

			me.overflowFlickityInstance = me.$overflowFlickityEl.data('flickity');
		});

		me.on('sleep', function() {
			me.parent.$flickityEl.off('.HomeCarouselContextLarge');
			me.overflowFlickityInstance.destroy();
			me.$overflowCarousel.detach();
		});
	}

	HomeCarouselContextLarge.prototype = Object.create(NS.ContextScaffold.prototype);
	HomeCarouselContextLarge.prototype.constructor = HomeCarouselContextLarge;
	var homeCarouselContextLargeProto = HomeCarouselContextLarge.prototype;

	homeCarouselContextLargeProto._build = function() {
		var me = this;

		me.parentFlickityInstance = me.parent.$flickityEl.data('flickity');

		me.parent.$flickityElClone
			.find('.home-carousel__item__body, .home-carousel__item__overlay').remove();

		me.$overflowCarousel = $('<div />')
			.attr({
				'class': 'media-carousel home-carousel__overflow-carousel',
				'data-aspect-ratio': me.parent.$primaryCarousel.attr('data-aspect-ratio')
			})
			.append(me.parent.$flickityElClone);

		me.$overflowFlickityEl = me.$overflowCarousel.find('> .media-carousel__items');
	};

	homeCarouselContextLargeProto._cellSelect = function(ev) {
		var me = this;

		var newParentIndex = me.parentFlickityInstance.selectedIndex;

		if (newParentIndex === me.parentIndex) return;

		var currentIndex = me.overflowFlickityInstance.selectedIndex;
		var length = me.overflowFlickityInstance.cells.length - 1;

		var direction;

		if (newParentIndex === 0 && currentIndex === length || newParentIndex === currentIndex + 1) {
			direction = 'next';
		} else if (newParentIndex === length && currentIndex === 0 || newParentIndex === currentIndex - 1) {
			direction = 'previous';
		}

		if (direction) {
			me.overflowFlickityInstance[direction]();
		} else {
			me.overflowFlickityInstance.select(newParentIndex);
		}

		me.parentIndex = newParentIndex;
	};

	NS.HomeCarouselContextLarge = HomeCarouselContextLarge;
})(window[NS], jQuery);
