/* global Expo */
(function(NS, $) {
	var appProto = NS.App.prototype;

	function Carousel($element) {
		this.$el = $element;
		this._init();
	}

	var carouselProto = Carousel.prototype;

	carouselProto._init = function() {
		var me = this;

		me.$window = $(window);

		me._build();
		me._bindings();
	}

	carouselProto._build = function() {
		var me = this;

		me.isDestroying = false;

		me.$carousel = me.$el.find('.media-carousel');
		me.$flickity = me.$carousel.find('.media-carousel__items');
		me.$footer = me.$carousel.find('.media-carousel__footer');
		me._itemIndex = null; // Zero based
		me._hasCaptions = me.$carousel.hasClass('media-carousel--has-captions');

		me._buildCaptions();
		me._buildPagination();

		me.$flickity.flickity({
			cellAlign: 'left',
			contain: true,
			setGallerySize: false,
			cellSelector: '.media-carousel__item',
			pageDots: false,
			prevNextButtons: false,
			lazyLoad: 1
		});

		me.flickityInstance = me.$flickity.data('flickity');
	}

	carouselProto._buildCaptions = function() {
		var me = this;

		me.$captionList = me.$footer.find('.media-carousel__caption-list');

		me.$captions = me.$flickity
			.find('.media-caption')
			.each(function() {
				var $this = $(this);
				$this.attr('data-caption-for', $this.closest('.media-carousel__item').index());
			})
			.appendTo(me.$captionList)
			.addClass('is-hidden');
	}

	carouselProto._buildPagination = function() {
		var me = this;

		me.$pagination = me.$footer.find('.media-carousel__pagination');
		me.$paginationTotal = me.$pagination.find('.media-carousel__pagination-item--total');
		me.$paginationCurrent = me.$pagination.find('.media-carousel__pagination-item--current');

		me.$paginationCurrent.css({
			width: (me.$paginationTotal.width() / parseFloat(me.$paginationTotal.css('font-size'), 10)) + 'em'
		});
	}

	carouselProto._bindings = function() {
		var me = this;

		me.$flickity.on('select.flickity', $.proxy(me._cellSelect, me));
		me.$window.on('resize.' + NS.NAMESPACE + '.carousel', $.proxy(me._resize, me));

		me._resize();
	}

	carouselProto._getCaptionFromCarouselIndex = function(index) {
		var me = this;

		return me.$captions.filter(function() {
			return $(this).attr('data-caption-for') === String(index);
		});
	}

	carouselProto._cellSelect = function(ev) {
		var me = this;

		var newItemIndex = me.flickityInstance.selectedIndex;

		// Indexes haven't changed, bail out
		if (newItemIndex === me._itemIndex) return;

		var tl;

		var $currentCaptionEl = me._getCaptionFromCarouselIndex(me._itemIndex);
		if ($currentCaptionEl.length) {
			tl = $currentCaptionEl.data('tl') || new TimelineLite();
			tl.clear();
			tl.to($currentCaptionEl[0], 0.25, {
				opacity: 0,
				y: '24',
				ease: Expo.easeOut,
				clearProps: 'transform, opacity',
				onComplete: function() {
					$currentCaptionEl.addClass('is-hidden');
				}
			});
		}

		var $newCaptionEl = me._getCaptionFromCarouselIndex(newItemIndex);
		if ($newCaptionEl.length) {
			$newCaptionEl.removeClass('is-hidden');
			tl = $newCaptionEl.data('tl') || new TimelineLite();
			tl.clear();
			tl.from($newCaptionEl[0], 1.5, {
				opacity: 0,
				y: '-64',
				ease: Expo.easeOut,
				clearProps: 'transform, opacity'
			});
		}

		me.$paginationCurrent.text(newItemIndex + 1);

		me._itemIndex = newItemIndex;
	}

	carouselProto._resize = function() {
		var me = this;

		// No captions, so don't worry about doing any of the following stuff
		if (!me._hasCaptions) return;

		// Reset inline styles
		me.$captions.css('marginLeft', '');

		var captionListWidth = me.$captionList.outerWidth();
		var $firstCaption = me.$captions.filter(':first');
		var captionWidth = $firstCaption.outerWidth();
		var paginationWidth = parseFloat(me.$pagination.outerWidth());

		$firstCaption.hide();
		var isCaptionCentered = $firstCaption.css('marginLeft') === 'auto';
		$firstCaption.show();

		// If we’re centered the marginRight computed value will be 'auto',
		// instead we want an absolute unit, so we calculate it manually
		var marginLeftCalculatedFromParent = (captionListWidth - captionWidth) / 2;

		if (paginationWidth > marginLeftCalculatedFromParent * 0.75) {
			var cssObj = {
				marginLeft: paginationWidth > 0 ? paginationWidth + 'px' : ''
			};

			me.$captions.css(cssObj);
		}

		var maxCaptionHeight = Math.max.apply(null, me.$captions.map(function() {
			return $(this).outerHeight(true);
		}).get());

		me.$captionList.height(maxCaptionHeight);
	};

	NS.Carousel = Carousel;
})(window[NS], jQuery);
