(function(NS, $) {
	var appProto = NS.App.prototype;
	var isLocked = false;
	var lockClass = '_lock-document-scrolling';
	var $document = $(document.documentElement);

	appProto.isScrollingLocked = function() {
		return isLocked;
	}

	appProto.lockScrolling = function() {
		if (isLocked) return;
		$document.addClass(lockClass);
		isLocked = !isLocked;
	}

	appProto.unlockScrolling = function() {
		if (!isLocked) return;
		$document.removeClass(lockClass);
		isLocked = !isLocked;
	}

})(window[NS], jQuery);
