(function(NS, $) {
	function HeaderContextSmall(parentInstance) {
		// Add some scaffolding (wake, sleep etc…)
		NS.ContextScaffold.apply(this, arguments);

		var me = this;
		me.$el = me.parent.$el;

		// Lock states to private variables
		var isMenuOpen = false;

		// Set default state, prevent being cached and loaded as checked
		me.$menuState = me.$el.find('.js--site-nav__menustate').prop('checked', isMenuOpen);

		me.isMenuStateChecked = function() {
			return me.$menuState.is(':checked');
		}

		me.isMenuOpen = function() {
			return isMenuOpen;
		}

		me.setIsMenuOpen = function(state) {
			isMenuOpen = state;
		}

		me._build();

		me.on('wake', function() {
			me._bindings();
		});

		me.on('sleep', function() {
			me.menuClose();
			me._unBindings();
		});
	}

	HeaderContextSmall.prototype = Object.create(NS.ContextScaffold.prototype);
	HeaderContextSmall.prototype.constructor = HeaderContextSmall;
	var headerContextSmallProto = HeaderContextSmall.prototype;

	headerContextSmallProto._build = function() {
		var me = this;

		me.$navBurger = me.$el.find('.js--navburger');

		me.$menuState.on('change', $.proxy(me.menuUpdate, me));
	}

	headerContextSmallProto._bindings = function() {
		var me = this;

		me.$el.on('click.' + NS.NAMESPACE + '.HeaderContextSmall', '.js--site-nav__menubutton', $.proxy(me._menuButtonClick, me));
		$(document.body).on('focusin.' + NS.NAMESPACE + '.HeaderContextSmall', $.proxy(me._menuFocusTrap, me));
	}

	headerContextSmallProto._unBindings = function() {
		var me = this;

		me.$el.off('click.' + NS.NAMESPACE + '.HeaderContextSmall');
		$(document.body).off('focusin.' + NS.NAMESPACE + '.HeaderContextSmall');
	}

	headerContextSmallProto.menuUpdate = function(ev) {
		var me = this;
		var isMenuOpen = me.isMenuOpen();
		var isDOMMenuStateOpen = me.isMenuStateChecked();

		// How did we end up here? No change is state so bail out
		if (isDOMMenuStateOpen === isMenuOpen) return;

		NS.appInstance[(!isMenuOpen ? '' : 'un') + 'lockScrolling']();
		me.setIsMenuOpen(!isMenuOpen);
	}

	headerContextSmallProto.menuOpen = function() {
		var me = this;

		if (me.isMenuStateChecked()) return;

		me.$menuState.prop('checked', true);
		me.menuUpdate();
	}

	headerContextSmallProto.menuClose = function() {
		var me = this;

		if (!me.isMenuStateChecked()) return;

		me.$menuState.prop('checked', false);
		me.menuUpdate();
	}

	headerContextSmallProto._menuButtonClick = function(ev) {
		ev.preventDefault();

		var me = this;
		var $target = $(ev.target);
		var menuToggleMethod = $target.attr('class').indexOf('--open') > -1 ? 'Open' : 'Close';
		me['menu' + menuToggleMethod]();

		$target.focus();
	}

	headerContextSmallProto._menuFocusTrap = function(ev) {
		var me = this;

		// Node.compareDocumentPosition()
		// https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
		if (me.isMenuOpen()) {
			if (!$.contains(me.$el[0], ev.target)) {
				$('.js--site-nav__firstfocussable').focus();
			}
		}
	}

	NS.HeaderContextSmall = HeaderContextSmall;
})(window[NS], jQuery);
