(function(NS, $) {
	var appProto = NS.App.prototype;

	function TabSetItem($element, tabSetInstance) {
		this.$el = $element;
		this.tabSetInstance = tabSetInstance;
		this._init();
	}

	// TabSetItem.prototype = Object.create(NS.ContentItemScaffold.prototype);
	var tabSetItemProto = TabSetItem.prototype;

	tabSetItemProto._init = function() {
		var me = this;

		// Extend with some context spying and switching
		NS.ContextController.call(me, {
			//small: NS.TabSetItemContextSmall,
			large: NS.TabSetItemContextLarge
		});

		// Find major child elements
		me.$inner = me.$el.find('> .js--tab-set-item__inner');
		me.$text = me.$inner.find('> .js--tab-set-item__text');
		me.$textInner = me.$text.find('> .js--tab-set-item__text-inner');
		me.$media = me.$inner.find('> .js--tab-set-item__media');
		me.$mediaItems = me.$media.find('> .js--tab-set-item__media-item');

		me.hasMedia = me.$el.hasClass('tab-set-item--has-media');

		// Lights out…And away we go!
		me._build();
	};

	tabSetItemProto._build = function() {
		var me = this;

		me.tabSetInstance
			.on('contextchange', function(newContext, oldContext) {
				return me._updateContext.apply(me, arguments);
			});

		// me.registerContentToLoadWithPage();
	};

	NS.TabSetItem = TabSetItem;
})(window[NS], jQuery);
