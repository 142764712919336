(function(NS, $) {

	var NAV_ITEM_TOGGLE_CLASSES = 'site-nav__item--sub-nav-open site-nav__item--sub-nav-closed';
	var SUB_NAV_TOGGLE_CLASSES = 'is-open is-closed';
	var TOUCH_ACTION_CSS_RULE = {
		'touch-action': 'none'
	};

	function SubNavExpander($element) {
		this.$el = $element;

		// Lock states to private variables
		var isNavOpen = false;

		this.isNavOpen = function() {
			return isNavOpen;
		}

		this.setIsNavOpen = function(state) {
			isNavOpen = state;
		}

		this._init();
	}

	SubNavExpander.prototype = {};
	SubNavExpander.prototype.constructor = SubNavExpander;
	var subNavExpanderProto = SubNavExpander.prototype;

	subNavExpanderProto._init = function() {
		var me = this;

		me.$navItemLabel = me.$el.find('> .js--site-nav__item-label');
		me.$subNav = me.$el.find('> .js--site-nav__sub-nav');

		me.isOpen = false;
		me.isAnimating = false;
		me.isDestroying = false;

		var isActiveByDefault = me.$el.hasClass('is-active');

		me.setIsNavOpen(isActiveByDefault);

		me.$el.addClass(NAV_ITEM_TOGGLE_CLASSES.split(' ')[Number(!isActiveByDefault)]);
		me.$subNav.addClass(SUB_NAV_TOGGLE_CLASSES.split(' ')[Number(!isActiveByDefault)]);

		me.$el
			.on('click.' + NS.NAMESPACE + '.SubNavExpander', $.proxy(me._update, me))
			.attr(TOUCH_ACTION_CSS_RULE);
	}

	subNavExpanderProto._update = function(ev) {
		var me = this;
		
		// If we’ve tapped a link, don’t do anything and continue with default clicking behaviour
		if (ev.target.tagName === 'A' && ev.target.getAttribute('href') !== null) {
			return;
		}

		if (me.isAnimating) return;

		var isNavOpen = me.isNavOpen();

		me['_' + (isNavOpen ? 'close' : 'open')]();

		me.isAnimating = true;
		me.setIsNavOpen(!isNavOpen);
	}

	subNavExpanderProto._open = function(ev) {
		var me = this;

		// Get existing timeline, or create a new one
		var transitionTimeline = $.data(me.$el[0], 'tl') || new TimelineLite();
		// Clear existing animation
		transitionTimeline.clear();

		me.$el.toggleClass(NAV_ITEM_TOGGLE_CLASSES);
		me.$subNav.toggleClass(SUB_NAV_TOGGLE_CLASSES);

		/* globals Expo */
		transitionTimeline
			.set(me.$subNav[0], {
				'height': 'auto'
			})
			.from(me.$subNav[0], 0.25, {
				height: 0,
				ease: Expo.easeOut,
				clearProps: 'all',
				onComplete: function() {
					me.isAnimating = false;
				}
			});

		$.data(me.$el[0], 'tl', transitionTimeline);
	}

	subNavExpanderProto._close = function(ev) {
		var me = this;

		me.$el.toggleClass(NAV_ITEM_TOGGLE_CLASSES);

		var transitionTimeline = $.data(me.$el[0], 'tl') || new TimelineLite();

		transitionTimeline
			.clear()
			.to(me.$subNav[0], 0.5, {
				height: 0,
				ease: Expo.easeOut,
				clearProps: 'height',
				onComplete: function() {
					me.$subNav.toggleClass(SUB_NAV_TOGGLE_CLASSES);
					me.isAnimating = false;
				}
			})
			.to(me.$subNav[0], 0.250, {
				opacity: 0,
				ease: Expo.easeOut,
				clearProps: 'all'
			}, '-=0.250');

		$.data(me.$el[0], 'tl', transitionTimeline);
	}

	subNavExpanderProto.destroy = function(ev) {
		var me = this;

		me.isDestroying = true;

		// Unbind
		me.$el.off('.SubNavExpander');

		me.$el.removeClass(NAV_ITEM_TOGGLE_CLASSES);
		me.$subNav.removeClass(SUB_NAV_TOGGLE_CLASSES);

		var tl = $.data(me.$el[0], 'tl') || new TimelineLite();

		if (tl) {
			tl.clear();
			tl.set([me.$el[0]], {
				clearProps: 'all'
			});
		}

		// Kill the data, TimelineLite instance and all
		$.removeData(me.$el[0]);
	}


	//
	function SiteNavContextSmall(parentInstance) {
		// Add some scaffolding (wake, sleep etc…)
		NS.ContextScaffold.apply(this, arguments);

		var me = this;
		me.$el = me.parent.$el;

		me.subNavInstances = [];
		me.subNavSelector = '.js--site-nav__item--has-sub-nav';

		me.on('wake', function() {
			me.subNavInstances = $(me.subNavSelector).get().map(function(element, index) {
				return new SubNavExpander($(element));
			});
		});

		me.on('sleep', function() {
			me.subNavInstances.forEach(function(instance, index, array) {
				instance.destroy();
			});

			me.subNavInstances = [];
		});
	}

	SiteNavContextSmall.prototype = Object.create(NS.ContextScaffold.prototype);
	SiteNavContextSmall.prototype.constructor = SiteNavContextSmall;
	var siteNavContextSmallProto = SiteNavContextSmall.prototype;

	NS.SiteNavContextSmall = SiteNavContextSmall;
})(window[NS], jQuery);
