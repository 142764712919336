(function(NS, $) {
	$(function() {
		var $window = $(window);
		var windowHeight;

		// vh units are buggy as hell, so manually controlling height with an injected rule
		$window.on('resize.' + NS.NAMESPACE + '.MediaHeightConstraints', function(ev) {
			var newWindowHeight = $window.height();

			if (newWindowHeight === windowHeight) return;

			windowHeight = newWindowHeight;

			var styleRules = ['.content-item--media-image .media-figure--image img {max-height: ' + (windowHeight / 100 * 80) + 'px;}'];

			// Lets not open this can of worms just yet considering the breakouts
			// NS.injectStyles(styleRules, 'media-figure--image--limit');
		});
	});
})(window[NS], jQuery);
