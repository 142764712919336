(function(NS, $) {
	var appProto = NS.App.prototype;

	function SiteNav($element) {
		this.$el = $element;
		this._init();
	}

	SiteNav.prototype = Object.create(EvEmitter.prototype);
	var siteNavProto = SiteNav.prototype;

	siteNavProto._init = function() {
		var me = this;

		// Extend with some context spying and switching
		NS.ContextController.call(me, {
			small: NS.SiteNavContextSmall,
			large: NS.SiteNavContextLarge
		});

		// On change init new context
		me.contextSpy.on('contextchange', function(newContext, oldContext) {
			return me._updateContext.apply(me, arguments);
		});
	}

	appProto.createMethods.push('_createSiteNav');
	appProto._createSiteNav = function() {
		this.siteNav = new SiteNav($('.js--site-nav'));
	};
})(window[NS], jQuery);
