/* global Expo, Linear */
(function(NS, $) {
	// https://github.com/cowboy/jquery-bbq/blob/master/jquery.ba-bbq.js#L1092
	function getFragment(string) {
		return '#' + string.replace(/^[^#]*#?(.*)$/, '$1');
	}

	function internalLink(hash, options) {
		if (hash.indexOf('#') > 0) hash = getFragment(hash);

		if (!/^#./.test(hash) || !$(hash).length) return;
		var $target = $(hash);

		if (options && options.e) {
			options.e.preventDefault();
			if (Modernizr.history) history.replaceState(null, null, hash);
		}

		var currentScrollY = (window.pageYOffset === undefined) ? (document.documentElement || document.body.parentNode || document.body).scrollTop : window.pageYOffset;
		var destinationScrollY = $target.offset().top;
		var scrollDistance = Math.abs(currentScrollY - destinationScrollY);
		var windowHeight = $(window).height();
		var duration = options && options.duration !== undefined ? options.duration : 0.5;

		// If scroll distance less than windowHeight, speed up the duration
		duration = scrollDistance < windowHeight ? (scrollDistance / windowHeight) * duration : duration;

		// HACK wait for offset() reflow tick
		setTimeout(function() {
			TweenLite.to(window, duration, {
				scrollTo: {
					y: destinationScrollY - (NS.appInstance.siteHeader.getHeightIfPinnable()) // We don’t know if the page has scrolled yet and whether the header is pinned or not
				},
				ease: duration === 0 ? Linear.easeNone : Expo.easeOut
			});
		}, 0);
	}

	// If the starting #hash location is on the current page, scroll smoothly to the real top (adjusting for the fixed header height).
	// Note that we can only do this after the fonts and images are all loaded, or else the $target.offset() may be wrong.
	$.when(NS.webfontsLoaded, NS.pageLoaded).done(function() {
		// HACK wait for next tick
		setTimeout(function() {
			if (location.hash) NS.internalLink(location.hash, {duration: 0});
		}, 0);
	});

	// Trap <a> clicks that have #hash locations on the current page
	$(document).on('click.' + NS.NAMESPACE + '.internallink', 'a', function(ev) {
		var href = $(this).attr('href');
		if (href && $.isUrlFragment(href)) internalLink(href, {e: ev});
	});

	NS.internalLink = internalLink;
})(window[NS], jQuery);
