(function(NS, $) {
	function ContextMonitor() {
		this._init();
	}

	ContextMonitor.prototype = Object.create(EvEmitter.prototype);
	var contextMonitorProto = ContextMonitor.prototype;

	contextMonitorProto._init = function() {
		var me = this;
		$(window).on('resize.contextmonitor orientationchange.contextmonitor', $.proxy(me.refresh, me));
	}
	contextMonitorProto.refresh = function() {
		this.emitEvent('refresh.contextmonitor');
	}
	contextMonitorProto.newSpy = function(element) {
		var me = this;
		var args = [].slice.call(arguments, 0, 1); // Only use the $element

		// Since no IE8 support, maybe update to Object.create() instead?
		// http://stackoverflow.com/questions/1606797/use-of-apply-with-new-operator-is-this-possible
		function constructSpy() {
			function F() {
				return Spy.apply(this, args.concat(me)); // Pass the contextMonitor too, sweet concat bro!
			}
			F.prototype = Spy.prototype;
			return new F();
		}

		var instance = constructSpy();
		return instance;
	}

	// Individual Spy
	function Spy(element, contextMonitor) {

		var me = this;
		var context = null;

		me.monitor = contextMonitor;
		me.$el = $(element);
		me.$spy = $('<div class="_context-spy" aria-hidden="true" />').appendTo(me.$el);

		me.monitor.on('refresh.contextmonitor', function() {
			me.refresh.call(me);
		});

		me.getContext = function() {
			return context;
		};

		me.setContext = function(newContext) {
			if (newContext && newContext !== context) {
				me.emitEvent('contextchange', [newContext, context]);
				context = newContext;
			}
		};

		// HACK allow time to bind listeners
		setTimeout(function() {
			me.refresh();
		}, 0);
	}

	Spy.prototype = Object.create(EvEmitter.prototype);
	Spy.prototype.refresh = function() {
		var me = this;
		var refreshedContext = me.$spy.css('fontFamily');

		// No context found
		if (!refreshedContext) return;

		// https://github.com/JoshBarr/on-media-query/blob/master/js/onmediaquery.js
		// Android browsers place a "," after an item in the font family list.
		// Most browsers either single or double quote the string.
		refreshedContext = refreshedContext.replace(/['",]/g, '');

		// Set context, if it’s the same it wont matter, setContext has a check
		me.setContext(refreshedContext);
	};

	// Define Monitor
	NS.contextMonitor = new ContextMonitor();
})(window[NS], jQuery);
