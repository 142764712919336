(function(NS, $) {
	function TabSetItemContextLarge(parentInstance) {
		// Add some scaffolding (wake, sleep etc…)
		NS.ContextScaffold.apply(this, arguments);

		var me = this;
		me.$el = me.parent.$el;

		me.hasMedia = me.parent.$media.length;
		if (!me.hasMedia) return;

		me.on('wake', function() {
			this.isDestroying = false;
			
			me._build();
			me._bindings();
		});

		me.on('sleep', function() {
			this.isDestroying = true;
			
			me._unbindings();
			me._destroy();
		});
	}

	TabSetItemContextLarge.prototype = Object.create(NS.ContextScaffold.prototype);
	TabSetItemContextLarge.prototype.constructor = TabSetItemContextLarge;
	var tabSetItemContextLargeProto = TabSetItemContextLarge.prototype;

	tabSetItemContextLargeProto._bindings = function() {
		var me = this;

		me.tabHeight = null;
		me.viewportHeight = null;

		var _refreshDelegate = function() {
			return me._layout();
		};
		me._refreshDelegate = _refreshDelegate;
		me.parent.tabSetInstance.on('refresh', _refreshDelegate);

		me._layout();
		me._initPinning();
	}

	tabSetItemContextLargeProto._initPinning = function() {
		var me = this;

		// Only do pinning if we have media to pin against
		if (!me.parent.hasMedia) return;

		me.parent.$text.pinny({
			behaviour: 'pinAndGhost',
			offsets: {
				pin: {
					measure: function() {
						return this.cache.tabItemOffsetY - this.cache.textHeightYRemainder;
					}
				},
				ghost: {
					measure: function() {
						var tabItemOffsetBottom = this.cache.tabItemOffsetY + this.cache.tabItemHeight;
						return tabItemOffsetBottom - Math.max(this.cache.textHeight, this.windowHeight);
					}
				}
			},
			cache: {
				tabItemOffsetY: function() {
					return me.$el.offset().top;
				},
				tabItemHeight: function() {
					return me.tabHeight;
				},
				textHeight: function() {
					return me.textHeight;
				},
				textHeightYRemainder: function() {
					return Math.max((this.windowHeight - this.cache.textHeight) / 2, 0);
				}
			}
		});

		me.pinnyInstance = me.parent.$text.data('pinny');

		me.parent.$text.on('statechange.pinny', function(ev, from, to, fromRefreshing, refreshing, instance) {
			var $this = $(this);
			var cssObject = {};

			if (to === "pinned") {
				cssObject.top = instance.cache.textHeightYRemainder;
			}

			if (from === "pinned") {
				cssObject.top = '';
			}

			if (to === "ghosting") {
				cssObject.bottom = instance.cache.textHeightYRemainder;
			}

			if (from === "ghosting") {
				cssObject.bottom = '';
			}

			$this.css(cssObject);
		});
	}

	tabSetItemContextLargeProto._unbindings = function() {
		var me = this;
		me.parent.tabSetInstance.off('refresh', me._refreshDelegate);
	}

	tabSetItemContextLargeProto._build = function() {
		var me = this;

		var $media = me.parent.$media;
		$media.masonry({
			percentPosition: true,
			itemSelector: '.js--tab-set-item__media-item',
			gutter: '.js--tab-set-item__gutter-sizer',
			transitionDuration: 0,
			resize: false
		});
		me.masonryInstance = $media.data('masonry');

		$media.imagesLoaded()
			.progress(function(instance, image) {
				me._layout();
			})
			.done(function(instance) {
				me.parent.tabSetInstance.refresh();
			});

		// me.parent.addNewContentToLoad($media.imagesLoaded());
	}

	tabSetItemContextLargeProto._layout = function() {
		if (this.isDestroying) return;
		
		var me = this;
		me.masonryInstance.layout();
		
		var onLayoutComplete = function(items) {
			if (this.isDestroying) return;
			
			me.masonryInstance.off('layoutComplete', onLayoutComplete);
			
			me.viewportHeight = $(window).height();
			me.textHeight = me.parent.$textInner.outerHeight();
			me.tabHeight = Math.max(me.masonryInstance.size.height, me.textHeight);

			// Set the inner height to the larger of the media or text heights
			me.parent.$inner.css('height', me.tabHeight);

			if (me.pinnyInstance) me.pinnyInstance.refresh();
		};
		
		onLayoutComplete();
		
		// vanilla JS, no event argument
		me.masonryInstance.on('layoutComplete', onLayoutComplete);
	}

	tabSetItemContextLargeProto._destroy = function() {
		var me = this;

		me.masonryInstance.destroy();

		if (me.pinnyInstance) {
			me.pinnyInstance.destroy();
			me.parent.$text.off('statechange.pinny');
		}

		// Undo set height
		me.parent.$inner.css('height', '');
	}

	NS.TabSetItemContextLarge = TabSetItemContextLarge;
})(window[NS], jQuery);
