(function($, window, document) {
	var Pinny = function(element, options) {
		this.$el = $(element);
		this.options = $.extend(true, {}, this.defaults, options);
		this._init();
	};

	Pinny.prototype = {
		constructor: 'Pinny',
		_init: function() {
			var me = this;

			// Create offset reference
			me.behaviourOffsets = $.extend(true, {}, me.behaviours[me.options.behaviour].offsets, me.options.offsets);
			me.behaviourCache = $.extend(true, {}, me.behaviours[me.options.behaviour].cache, me.options.cache);

			me._viewState = null;
			me._refreshingState = null;
			me.isDestroying = false;

			me.$window = $(window);

			//
			me.$el.attr('data-pinny', '');

			// Bindings
			me._bindings();
		},
		defaults: {
			behaviour: 'pin'
		},
		behaviours: {
			pinned: {
				cache: {},
				offsets: {
					poaching: {
						measure: function() {
							return this.$el.offset().top;
						}
					}
				},
				behaviour: function() {
					var me = this;
					var scrollTop = me.scrollTop;

					if (scrollTop >= (me.offsets.pin instanceof Function ? me.offsets.pin.call(me) : me.offsets.pin)) {
						if (me.getState() !== 'pinned') {
							me._setState('pinned');
						}
					} else if (me.getState() !== 'default') {
						me._setState('default');
					}
				}
			},
			pinAndGhost: {
				cache: {
					height: function() {
						return this.$el.height();
					}
				},
				offsets: {
					pin: {
						measure: function() {
							return this.$el.offset().top;
						}
					},
					ghost: {
						measure: function() {
							throw new Error("$.pinny behaviour: pinAndGhost, ghost offset measure is undefined");
						}
					}
				},
				behaviour: function() {
					var me = this;
					var scrollTop = me.scrollTop;
					var offsets = me.offsets;

					// Run live if needed, otherwise cache the cacged. Herp derp.
					var pinOffset = (offsets.pin instanceof Function ? offsets.pin.call(me) : offsets.pin);
					var ghostOffset = (offsets.ghost instanceof Function ? offsets.ghost.call(me) : offsets.ghost);

					// Enough to pin
					if (scrollTop >= pinOffset) {
						// Enough to start ghosting
						if (scrollTop >= ghostOffset) {
							if (me.getState() !== 'ghosting') {
								me._setState('ghosting');
							}
							// var ghostOffset = Math.max(Math.min(scrollTop - offsets.ghost + me.cache.height, me.cache.height), 0);
						} else if (me.getState() !== 'pinned') {
							me._setState('pinned');
						}
					} else if (me.getState() !== 'default') {
						me._setState('default');
					}
				}
			}
		},
		_bindings: function() {
			var me = this;
			var _resizeDelegate = function(ev) {
				return me.isDestroying ? false : me.refresh();
			};
			var _scrollDelegate = function(ev) {
				return me.isDestroying ? false : me.scroll();
			};

			me.refresh();

			// More ugly to ensure GUID is accessible
			me._resizeDelegate = _resizeDelegate;
			me._scrollDelegate = _scrollDelegate;

			me.$window
				.on('resize.pinny', me._resizeDelegate)
				.on('scroll.pinny', me._scrollDelegate);
		},
		refresh: function() {
			var me = this;

			// Reset inline styles
			me._setState('default', true);

			var _rf = me.$el[0].offsetWidth;

			me.windowHeight = me.$window.height();

			// Cache
			me.cache = {};
			$.each(me.behaviourCache, function(key, value) {
				me.cache[key] = value instanceof Function ? value.call(me) : value;
			});

			// Cache offsets
			me.offsets = {};
			$.each(me.behaviourOffsets, function(key, value) {
				if (value.calculateOnTick) {
					me.offsets[key] = me.behaviourOffsets[key].measure;
					return true;
				}

				var isFunction = me.behaviourOffsets[key].measure instanceof Function;

				try {
					if (isFunction) me.behaviourOffsets[key].measure.call(me);
				} catch (e) {
					throw e;
				}

				// If measure defined and a function, run it else set to itself (hopefully a string of a fixed value)
				me.offsets[key] = (isFunction ? me.behaviourOffsets[key].measure.call(me) : me.behaviourOffsets[key].measure);
			});

			me.scroll();
		},
		scroll: function() {
			var me = this;

			me.scrollTop = (window.pageYOffset === undefined) ? (document.documentElement || document.body.parentNode || document.body).scrollTop : window.pageYOffset;

			me.behaviours[me.options.behaviour].behaviour.call(me);
		},
		_setState: function(state, refreshing) {
			var me = this;
			var fromState = me._viewState;
			var fromRefreshing = me._refreshingState;
			me._refreshingState = Boolean(refreshing);

			me._viewState = state;
			me.$el
				.attr('data-pinny-state', state)
				.trigger('statechange.pinny', [fromState, state, fromRefreshing, me.refreshingState, me]);
		},
		getState: function() {
			var me = this;

			return me._viewState;
		},
		addMethod: function() {
			// TODO
		},
		destroy: function() {
			var me = this;

			me.isDestroying = true;

			// Return to default state and trigger statechange event
			me._setState('default');

			$.removeData(me.$el[0], 'pinny');

			me.$window
				.off('resize.pinny', me._resizeDelegate)
				.off('scroll.pinny', me._scrollDelegate);

			me.$el
			.removeAttr('data-pinny-state')
			.removeClass('js-pinny');
		}
	};

	$.bridget('pinny', Pinny);
})(jQuery, window, document);
