(function(NS, $) {
	
	var TOUCH_ACTION_ATTR = {
		'touch-action': 'none'
	};
	
	var MOUSELEAVE_DELAY = 500;

	//
	function SubNavDropdown($element, SiteNavContextLargeInstance) {
		this.$el = $element;
		this.parentContextInstance = SiteNavContextLargeInstance;
		
		this._init();
	}

	SubNavDropdown.prototype = {};
	SubNavDropdown.prototype.constructor = SubNavDropdown;
	var subNavDropdownProto = SubNavDropdown.prototype;

	subNavDropdownProto._init = function() {
		var me = this;
		
		this.$el[0].tabIndex = 0;

		me.$navItemLabel = me.$el.find('> .js--site-nav__item-label');
		me.$subNav = me.$el.find('> .js--site-nav__sub-nav');

		me.isShown = false;
		me.isDestroying = false;

		// Add touch-action as an attribute for PEP, this is also used as CSS hook for forwards compatibility
		me.$el.attr(TOUCH_ACTION_ATTR);
		me.$navItemLabel.attr(TOUCH_ACTION_ATTR);

		// Not today! Maybe never.
		// This would get rid of the compatibility click hizzah!
		// me.$el.attr('touch-action', 'none');
		// .js--site-nav__item--has-sub-nav {touch-action: none;}

		// Further work required including:
		// Suppress `pointerleave` for non hover devices (fired with `pointerup`) and not trigger hide
		// https://www.w3.org/TR/pointerevents/#the-pointerleave-event

		// Keyboard support
		// http://a11yproject.com/patterns/
		// http://codepen.io/grayghostvisuals/pen/ltjDa
		// https://patrickhlauke.github.io/touch/css-dropdown/mouse-keyboard-touch.html
		
		this._onMouseEnter = this._onMouseEnter.bind(this);
		this._onMouseLeave = this._onMouseLeave.bind(this);
		this._onFocus = this._onFocus.bind(this);
		this._onDocumentDeactivationTrigger = this._onDocumentDeactivationTrigger.bind(this);
		this._hideTimeout = this._hideTimeout.bind(this);

		me.$el
			.on('mouseenter.' + NS.NAMESPACE + '.SubNavDropdown', this._onMouseEnter)
			.on('mouseleave.' + NS.NAMESPACE + '.SubNavDropdown', this._onMouseLeave)
			.on('focus.' + NS.NAMESPACE + '.SubNavDropdown', this._onFocus);
	}
	
	subNavDropdownProto._onMouseEnter = function(ev) {
		this._show();
	}
	
	subNavDropdownProto._onMouseLeave = function(ev) {
		this._hide(ev);
	}
	
	subNavDropdownProto._onFocus = function(ev) {
		this._show();
	}
	
	subNavDropdownProto._bindingsToHide = function(ev) {
		$(document).on('focusin.' + NS.NAMESPACE, this._onDocumentDeactivationTrigger);
	}
	
	subNavDropdownProto._bindingsToHideTeardown = function(ev) {
		$(document).off('focusin.' + NS.NAMESPACE, this._onDocumentDeactivationTrigger);
	}
	
	subNavDropdownProto._onDocumentDeactivationTrigger = function(ev) {
		if (!(this.$el[0].contains(ev.target))) {
			this._hide();
		}
	}
	
	subNavDropdownProto._mouseLeaveDelay = function() {
		if (this.mouseLeaveTimeout) window.clearTimeout(this.mouseLeaveTimeout);
		
		this.mouseLeaveTimeout = window.setTimeout(this._hideTimeout, MOUSELEAVE_DELAY);
	}

	subNavDropdownProto._show = function() {
		var me = this;
		
		if (this.mouseLeaveTimeout) window.clearTimeout(this.mouseLeaveTimeout);

		// If we’re already shown bail out!
		if (me.isShown) return;
		
		this.parentContextInstance.subNavInstances.forEach(function(subNavInstance, index) {
			subNavInstance._hide();
		});

		// Pop the current nav item on top
		me.$el.addClass('-is-active-sub-nav');
		me.$subNav.show();
		
		me._bindingsToHide();
		
		me.isShown = true;
	}

	subNavDropdownProto._hide = function(ev) {
		var me = this;

		// If we’re already hiding bail out!
		if (!me.isShown) return;
		
		if (ev && ev.type === 'mouseleave') {
			me._mouseLeaveDelay();
		} else {
			me._hideLogic();
		}
	}
	
	subNavDropdownProto._hideLogic = function() {
		// Remove the nav item from the top
		this.$el.removeClass('-is-active-sub-nav');
		this.$subNav.hide();
		
		this._bindingsToHideTeardown();
		
		this.isShown = false;
	}
	
	subNavDropdownProto._hideTimeout = function(ev) {
		window.clearTimeout(this.mouseLeaveTimeout);
		this._hideLogic();
	}

	subNavDropdownProto.destroy = function(ev) {
		var me = this;
		me.isDestroying = true;

		me.$el
			.removeAttr('touch-action')
			.off('.SubNavDropdown');

		me.$navItemLabel
			.removeAttr('touch-action');

		// Hide and remove styles if not already
		me._hide();
	}

	//
	function SiteNavContextLarge(parentInstance) {
		// Add some scaffolding (wake, sleep etc…)
		NS.ContextScaffold.apply(this, arguments);

		var me = this;
		me.$el = me.parent.$el;

		me.subNavInstances = [];
		me.subNavSelector = '.js--site-nav__item--has-sub-nav';

		me.on('wake', function() {
			me.subNavInstances = $(me.subNavSelector).get().map(function(element, index) {
				return new SubNavDropdown($(element), me);
			});
		});

		me.on('sleep', function() {
			me.subNavInstances.forEach(function(instance, index, array) {
				instance.destroy();
			});

			me.subNavInstances = [];
		});
	}

	SiteNavContextLarge.prototype = Object.create(NS.ContextScaffold.prototype);
	SiteNavContextLarge.prototype.constructor = SiteNavContextLarge;
	var siteNavContextLargeProto = SiteNavContextLarge.prototype;

	NS.SiteNavContextLarge = SiteNavContextLarge;
})(window[NS], jQuery);
