(function(NS, $) {
	function ContextController(contexts) {
		var me = this;

		me._contextInstances = {};
		me.contextSpy = NS.contextMonitor.newSpy(me.$el);

		me._updateContext = function(newContext, oldContext) {
			if (oldContext) {
				if (typeof me._contextInstances[oldContext] !== 'undefined') {
					me._contextInstances[oldContext].sleep();
				}
			}

			if (newContext && contexts[newContext] instanceof Function) {
				if (typeof me._contextInstances[newContext] === 'undefined') {
					me._contextInstances[newContext] = new contexts[newContext](me);
				}

				me._contextInstances[newContext].wake();
			}
		};
	}

	// Expose Context Constructor
	NS.ContextController = ContextController;
})(window[NS], jQuery);
