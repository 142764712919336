/* globals Expo */
(function(NS, $) {
	var appProto = NS.App.prototype;

	function HomeCarousel($element) {
		this.$el = $element;
		this._init();
	}

	HomeCarousel.prototype = Object.create(EvEmitter.prototype);
	var homeCarouselProto = HomeCarousel.prototype;

	homeCarouselProto._init = function() {
		var me = this;

		me.$primaryCarousel = me.$el.find('.home-carousel__primary-carousel');
		me.isMultipleItem = me.$primaryCarousel.hasClass('is-multiple-item');

		me._buildCarousel();
		me._buildPagination();

		if (me.isMultipleItem) {
			// Grab some default module scaffolding
			me._bindings();

			// Extend with some context spying and switching
			NS.ContextController.call(me, {
				large: NS.HomeCarouselContextLarge
			});

			me.contextSpy.on('contextchange', function(newContext, oldContext) {
				me._updateContext.apply(me, arguments);
			});
		}
	}

	homeCarouselProto._buildCarousel = function() {
		var me = this;

		me.$flickityEl = me.$primaryCarousel.find('> .media-carousel__items');

		me.$flickityElClone = me.$flickityEl.clone();

		me.$flickityEl.flickity({
			setGallerySize: false,
			cellSelector: '.media-carousel__item',
			draggable: me.isMultipleItem,
			wrapAround: true,
			pageDots: false,
			prevNextButtons: false,
			lazyLoad: 1
		});

		me.flickityInstance = me.$flickityEl.data('flickity');
	}

	homeCarouselProto._buildPagination = function() {
		var me = this;

		me.$pagination = me.$el.find('.home-carousel__pagination');
		me.$paginationTotal = me.$pagination.find('.home-carousel__pagination-item--total');
		me.$paginationCurrent = me.$pagination.find('.home-carousel__pagination-item--current');

		me.$paginationCurrent.css({
			width: (me.$paginationTotal.width() / parseFloat(me.$paginationTotal.css('font-size'), 10)) + 'em'
		});
	}

	homeCarouselProto._bindings = function() {
		var me = this;

		me.$navigationEl = me.$el.find('.home-carousel__navigation');
		me.$navigationButtons = me.$navigationEl.find('.home-carousel__navigation__button');

		me.$navigationEl
			.on('mouseenter.' + NS.NAMESPACE + ' mouseleave.' + NS.NAMESPACE, '.home-carousel__navigation__button', function(ev) {
				var $this = $(this);

				// Bail out if no SVG support
				if (!Modernizr.inlinesvg) {
					return;
				}

				// Find me the SVG element
				var $svg = $this.children('svg');
				// Set the direction based on existance of class match
				var direction = $this.attr('class').indexOf('--previous') > -1 ? -1 : 1;
				var xOffset = 125 * direction;

				// Get existing timeline, or create a new one
				var tl = $this.data('tl') || new TimelineLite();
				// Clear existing animation (could be still animating mouseenter or mouseleave)
				tl.clear();

				if (ev.type === 'mouseenter') {
					tl
						.to($svg[0], 0.250, {
							xPercent: xOffset,
							ease: Expo.easeOut
						})
						.set($svg[0], {
							xPercent: xOffset * -1}
						);
				}

				tl.to($svg[0], 0.500, {
					xPercent: 0,
					ease: Expo.easeOut
				});

				// Save timeline instance
				$this.data('tl', tl);
			})
			.on('click.' + NS.NAMESPACE, '.home-carousel__navigation__button', function(ev) {
				var $this = $(this);
				var method = $this.attr('class').indexOf('--previous') > -1 ? 'previous' : 'next';
				me.$flickityEl.flickity(method);
			});

		me.$flickityEl.on('select.flickity', function() {
			me.$paginationCurrent.text(me.flickityInstance.selectedIndex + 1);
		});
	}

	NS.HomeCarousel = HomeCarousel;
})(window[NS], jQuery);
